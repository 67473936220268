import { DateTime } from "luxon";
import {
  Action,
  ActionType,
  ActionStatus,
  Adapter,
  BillingRequestsStatus,
  BillingRequestResource,
  BillingRequestFlowResource,
  InstitutionResource,
  BillingRequestsLinks,
  AddressLookupCreateResponseBody,
  AddressDetailSelfResponseBody,
  AddressLookupCreateRequestBody,
  AddressDetailResource,
  CustomerBillingDetailClass,
  PlanResource,
  DayOfWeek,
  IntervalUnit,
  ResourcesCustomer,
  CustomerBankAccount,
  Institution,
  AvailableDebitSchemeEnum,
  SourceCurrencyEnum as Currency,
  BillingRequestsPaymentRequest,
  BillingRequestsMandateRequest,
  MandateRequestVerify,
  BankAuthorisationResource,
  Type,
  PeriodEnum,
  PrefilledCustomerPrefilledCustomer,
  MandateMigrationResource,
  MandateMigrationsStatus,
  MandateRequestConstraints,
  InstitutionListResponseBody,
  BillingRequestSelfResponseBody,
  CustomerBankAccountResource,
  CustomerBankAccountSelfResponseBody,
  InstalmentTemplateResource,
} from "@gocardless/api/dashboard/types";

/*
 * # Core fixtures
 *
 * What follow are a collection of dynamic fixture generators. In all cases, we
 * should prefer using these dynamic fixtures over hardcoding them into multiple
 * places, as they are more flexible and encourage fixtures to remain similar
 * across multiple tests.
 *
 * The @gocardless/api package provides fixtures for some resources. We embed these
 * helpers in our app because they are very specific to Billing Request Flows,
 * and the resources we use (Billing Requests) are particularly complex.
 */

// Generate a new Billing Request with sensible defaults. Must call
// billingRequestWithActions on the result to get any actions.
export const billingRequestSelf = (
  data?: Partial<BillingRequestResource>,
  status?: BillingRequestsStatus
): BillingRequestResource => {
  let links: BillingRequestsLinks = {
    customer: "CU123",
    ...data?.links,
  };

  if (data?.payment_request) {
    links = {
      payment_request: "PRQ123",
      ...links,
    };
    data.payment_request = {
      description: "Large pot of Marmalade",
      currency: "GBP",
      scheme: "faster_payments",
      amount: 500,
      default_max_amount: 840,
      default_min_amount: 0,
      ...data.payment_request,
    };
  }
  if (data?.mandate_request) {
    if (status === BillingRequestsStatus.Fulfilled) {
      links = {
        mandate_request_mandate: "MD123",
        ...links,
      };
    }

    links = {
      mandate_request: "MRQ123",
      customer_billing_detail: "CBD123",
      ...links,
    };
    data.mandate_request = {
      currency: "GBP",
      scheme: "bacs",
      links: {},
      ...data.mandate_request,
    };
  }
  if (data?.resources) {
    data.resources = {
      // Empty customer and customer billing detail
      customer: {},
      customer_billing_detail: {},
      // Bank account is null, as that's how we begin
      customer_bank_account: null,
      ...data.resources,
    };
  }

  return {
    id: "BRQ123",
    status: BillingRequestsStatus.Pending,
    created_at: new Date(),
    creditor_name: "Hotel Park Grand",
    actions: data?.actions || generateBillingRequestActions(),
    links,
    ...(data || {}),
  };
};

// Take an existing billing request and merge actions onto it. This is an easy way to
// modify an existing billing request into something that should be returned after
// completing an action.
export const extendBillingRequestActions = (
  billingRequest: BillingRequestResource,
  params: {
    ChooseCurrency?: Partial<Action>;
    CollectCustomerDetails?: Partial<Action>;
    SelectInstitution?: Partial<Action>;
    CollectBankAccount?: Partial<Action>;
    BankAuthorisation?: Partial<Action>;
    ConfirmPayerDetails?: Partial<Action>;
    CollectAmount?: Partial<Action>;
  }
) => {
  return {
    ...billingRequest,
    actions: generateBillingRequestActions({
      ...params,
      Base: billingRequest,
    }),
  };
};

// Create a default actions list, each action merged with the optional parameters.
export const generateBillingRequestActions = ({
  Base,
  ChooseCurrency,
  CollectAmount,
  CollectCustomerDetails,
  SelectInstitution,
  CollectBankAccount,
  BankAuthorisation,
  ConfirmPayerDetails,
}: {
  Base?: BillingRequestResource;
  CollectAmount?: Partial<Action>;
  ChooseCurrency?: Partial<Action>;
  CollectCustomerDetails?: Partial<Action>;
  SelectInstitution?: Partial<Action>;
  CollectBankAccount?: Partial<Action>;
  BankAuthorisation?: Partial<Action>;
  ConfirmPayerDetails?: Partial<Action>;
} = {}): Action[] => {
  const defaults: Partial<Action> = {
    required: true,
    requires_actions: [],
    completes_actions: [],
    status: ActionStatus.Pending,
  };

  const findAction = (actionType: ActionType) => {
    return Base?.actions?.find((a) => a.type === actionType) || {};
  };

  const actions = [
    {
      type: ActionType.ChooseCurrency,
      ...defaults,
      ...findAction(ActionType.ChooseCurrency),
      ...ChooseCurrency,
    },
    {
      type: ActionType.CollectCustomerDetails,
      requires_actions: [ActionType.ChooseCurrency],
      collect_customer_details: {
        default_country_code: "GB",
      },
      ...defaults,
      ...findAction(ActionType.CollectCustomerDetails),
      ...CollectCustomerDetails,
    },
    {
      type: ActionType.SelectInstitution,
      ...defaults,
      required: false,
      ...findAction(ActionType.SelectInstitution),
      ...SelectInstitution,
    },
    {
      type: ActionType.CollectBankAccount,
      requires_actions: [ActionType.ChooseCurrency],
      available_country_codes: ["GB"],
      ...defaults,
      ...findAction(ActionType.CollectBankAccount),
      ...CollectBankAccount,
    },
    {
      type: ActionType.BankAuthorisation,
      requires_actions: [
        ActionType.ChooseCurrency,
        ActionType.CollectBankAccount,
        ActionType.SelectInstitution,
      ],
      bank_authorisation: {
        adapter: Adapter.OpenBankingGatewayPis,
      },
      ...defaults,
      ...findAction(ActionType.BankAuthorisation),
      ...BankAuthorisation,
    },
  ];

  if (Base?.payment_request) {
    actions.push({
      type: ActionType.CollectAmount,
      required: true,
      requires_actions: [],
      status: ActionStatus.Pending,
      ...findAction(ActionType.CollectAmount),
      ...CollectAmount,
    });
  }

  if (Base?.mandate_request) {
    actions.push({
      type: ActionType.ConfirmPayerDetails,
      required: false,
      requires_actions: [
        ActionType.CollectCustomerDetails,
        ActionType.CollectBankAccount,
      ],
      status: ActionStatus.Pending,
      ...findAction(ActionType.ConfirmPayerDetails),
      ...ConfirmPayerDetails,
    });
  }

  return actions;
};

export const billingRequestFlowSelf = (
  data?: Partial<BillingRequestFlowResource>
): BillingRequestFlowResource => {
  return {
    id: "BRF123456",
    auto_fulfil: true,
    lock_customer_details: false,
    lock_bank_account: false,
    redirect_uri: null,
    show_success_redirect_button: false,
    created_at: new Date(),
    session_token: "sesh_123",
    links: {
      billing_request: "BRQ123",
    },
    ...data,
  };
};

export const prefilledCustomerDataSelf = (
  data?: Partial<PrefilledCustomerPrefilledCustomer>
): PrefilledCustomerPrefilledCustomer => {
  return {
    address_line1: "Sample address",
    address_line2: "Sample address 2",
    address_line3: "Sample address 3",
    city: "Sample City",
    country_code: "UK",
    danish_identity_number: "DAN123",
    email: "sample@example.org",
    family_name: "Family",
    given_name: "Given",
    postal_code: "N0 123",
    region: "WA",
    swedish_identity_number: "SWE123",
    ...data,
  };
};

export const bankAuthorisationSelf = (
  data?: Partial<BankAuthorisationResource>
): BankAuthorisationResource => {
  return {
    id: "BAU123",
    url: "https://example.com",
    qr_code_url: "https://i.imgur.com/7WCD4ZY.png",
    redirect_uri: "https://somewhere.com/callback",
    authorisation_type: Type.Payment,
    last_visited_at: null,
    created_at: new Date(),
    expires_at: DateTime.utc().plus({ minutes: 5 }).toJSDate(),
    links: {
      billing_request: "BRQ123",
      institution: "monzo",
    },
    ...data,
  };
};

// For attaching into the Billing Request, under resources.customer
export const customerSelf = (
  data?: Partial<ResourcesCustomer>
): ResourcesCustomer => {
  return {
    id: "CU123",
    email: "paddington@bearthings.com",
    given_name: "Paddington",
    family_name: "Bear",
    company_name: null,
    language: "en",
    phone_number: null,
    metadata: {},
    ...data,
  };
};

// For attaching into the Billing Request, under resources.customer
export const companyCustomerSelf = (
  data?: Partial<ResourcesCustomer>
): ResourcesCustomer => {
  return {
    id: "CU123",
    email: "info@solutions.com",
    given_name: null,
    family_name: null,
    company_name: "Solutions Inc",
    language: "en",
    phone_number: null,
    metadata: {},
    ...data,
  };
};

// For attaching into the Billing Request, under resources.customer_bank_account
export const customerBankAccountSelf = (
  data?: Partial<CustomerBankAccount>
): CustomerBankAccount => {
  return {
    id: "BA123",
    account_number_ending: "11",
    account_holder_name: "PADDINGTON BEAR",
    account_type: null,
    bank_name: "BARCLAYS BANK PLC",
    currency: "GBP",
    country_code: "GB",
    metadata: {},
    enabled: true,
    links: {
      customer: "CU123",
    },
    ...data,
  };
};

// Create Instalment Template details
export const instalmentTemplateSelf = (
  data?: Partial<InstalmentTemplateResource>
): InstalmentTemplateResource => {
  return {
    id: "ITPL123",
    name: "Discover Espresso Coffee Instalment Template",
    currency: Currency.Gbp,
    month: null,
    day_of_month: null,
    day_of_week: DayOfWeek.Monday,
    interval: 0,
    interval_unit: IntervalUnit.Weekly,
    instalments: [
      { charge_date: new Date("2020-01-01T00:00:00.000Z"), amount: 200 },
      { charge_date: new Date("2020-02-01T00:00:00.000Z"), amount: 100 },
      { charge_date: new Date("2020-03-01T00:00:00.000Z"), amount: 100 },
      { charge_date: new Date("2020-04-01T00:00:00.000Z"), amount: 100 },
    ],
    ...data,
  };
};

// Create Plan details
export const planSelf = (data?: Partial<PlanResource>): PlanResource => {
  return {
    id: "PL123",
    name: "Discover Espresso Coffee Subscription",
    month: null,
    day_of_month: null,
    day_of_week: DayOfWeek.Monday,
    interval: 0,
    interval_unit: IntervalUnit.Weekly,
    count: 10,
    ...data,
  };
};

// For attaching into the Billing Request, under resources.customer_billing_detail
export const customerBillingDetailSelf = (
  data?: Partial<CustomerBillingDetailClass>
): CustomerBillingDetailClass => {
  return {
    address_line1: "8 Hermitage St",
    city: "London",
    country_code: "GB",
    postal_code: "W2 1BE",
    ...data,
  };
};

export const institutionSelf = (data?: Partial<Institution>): Institution => {
  return {
    id: "monzo",
    name: "Monzo",
    icon_url:
      "https://storage.googleapis.com/gc-prd-institution_icons-production/monzo",
    roles: ["institutions_list", "heartbeat", "read_refund_account"],
    ...data,
  };
};

export const generateInstitutionList = (): InstitutionResource[] => {
  return [
    {
      id: "STARLING_SRLGGB3L",
      name: "Starling Bank",
      logo_url:
        "https://images.yapily.com/image/17c5ba24-fff2-4ffd-b84d-b7697cb40232?size=0",
      icon_url:
        "https://storage.googleapis.com/gc-prd-institution_icons-production/starling",
    },
    {
      id: "MONZO_MONZGB2L",
      name: "Monzo",
      logo_url:
        "https://images.yapily.com/image/332bb781-3cc2-4f3e-ae79-1aba09fac991?size=0",
      icon_url:
        "https://storage.googleapis.com/gc-prd-institution_icons-production/monzo",
    },
    {
      id: "REVOLUT_REVOGB21",
      name: "Revolut",
      logo_url:
        "https://images.yapily.com/image/fe9124de-3dd2-4b6f-a519-ad5f9e2b594e?size=0",
      icon_url:
        "https://storage.googleapis.com/gc-prd-institution_icons-production/revolut",
    },
    {
      id: "RBS_GB_RBSSGBKC",
      name: "Royal Bank of Scotland",
      logo_url:
        "https://images.yapily.com/image/7a689514-dda3-4550-917b-c0243a9eabd3?size=0",
      icon_url:
        "https://storage.googleapis.com/gc-prd-institution_icons-production/rbs",
    },
    {
      id: "rbsbusiness",
      name: "Royal Bank of Scotland Bankline",
      logo_url:
        "https://images.yapily.com/image/0aaec899-70ac-47e9-ab53-f53587c88c3c?size=0",
      icon_url:
        "https://storage.googleapis.com/gc-prd-institution_icons-production/rbsbusiness",
    },
    {
      id: "LLOYDS_LOYDGB2L",
      name: "Lloyds (Personal)",
      logo_url:
        "https://images.yapily.com/image/da8f3859-978a-4027-863f-5d01d794d4c4?size=0",
      icon_url:
        "https://storage.googleapis.com/gc-prd-institution_icons-production/lloyds",
    },
    {
      id: "HSBC_HBUKGB4B",
      name: "HSBC Personal",
      logo_url:
        "https://images.yapily.com/image/da8f3859-978a-4027-863f-5d01d794d4c4?size=0",
      icon_url:
        "https://storage.googleapis.com/gc-prd-institution_icons-production/hsbc_uk",
    },
    {
      id: "NATIONWIDE_NAIAGB21",
      name: "Nationwide",
      logo_url:
        "https://images.yapily.com/image/da8f3859-978a-4027-863f-5d01d794d4c4?size=0",
      icon_url:
        "https://storage.googleapis.com/gc-prd-institution_icons-production/nationwide",
    },
    {
      id: "SANTANDER_GB_ABBYGB2L",
      name: "Santander",
      logo_url:
        "https://images.yapily.com/image/da8f3859-978a-4027-863f-5d01d794d4c4?size=0",
      icon_url:
        "https://storage.googleapis.com/gc-prd-institution_icons-production/santander_uk",
    },
    {
      id: "NATWEST_NWBKGB2L",
      name: "Natwest",
      logo_url:
        "https://images.yapily.com/image/da8f3859-978a-4027-863f-5d01d794d4c4?size=0",
      icon_url:
        "https://storage.googleapis.com/gc-prd-institution_icons-production/natwest",
    },
    {
      id: "HALIFAX_HLFXGB22",
      name: "Halifax Personal",
      logo_url:
        "https://images.yapily.com/image/da8f3859-978a-4027-863f-5d01d794d4c4?size=0",
      icon_url:
        "https://storage.googleapis.com/gc-prd-institution_icons-production/halifax",
    },
    {
      id: "RBS_GB_RBSSGBKC",
      name: "Bank of Scotland (Personal)",
      logo_url:
        "https://images.yapily.com/image/da8f3859-978a-4027-863f-5d01d794d4c4?size=0",
      icon_url:
        "https://storage.googleapis.com/gc-prd-institution_icons-production/bankofscotland",
    },
    {
      id: "BARCLAYS_BUKBGB22",
      name: "Barclays Personal",
      logo_url:
        "https://images.yapily.com/image/da8f3859-978a-4027-863f-5d01d794d4c4?size=0",
      icon_url:
        "https://storage.googleapis.com/gc-prd-institution_icons-production/barclays",
    },
    {
      id: "BARCLAYS_BUSINESS_BUKBGB22",
      name: "Barclays Business",
      logo_url:
        "https://images.yapily.com/image/da8f3859-978a-4027-863f-5d01d794d4c4?size=0",
      icon_url:
        "https://storage.googleapis.com/gc-prd-institution_icons-production/barclays",
    },
  ];
};

export const addressDetailSelf = async () => {
  return addressDetail;
};

export const addressLookupCreate = async ({
  id,
}: AddressLookupCreateRequestBody): Promise<AddressLookupCreateResponseBody> => {
  if (id === NESTED_ADDRESS) {
    return nestedAddresses;
  }
  return addressLookupsResults;
};

const NESTED_ADDRESS = "GB|RM|ENG|3GG-N19";

export const addressLookupsResults: AddressLookupCreateResponseBody = {
  address_lookups: [
    {
      address_text: "example values",
      id: "resultId1",
      description: "<city>, <post code>",
      has_nested_results: false,
    },
    {
      address_text: "<number>, <street name>",
      id: "resultId1",
      description: "<city>, <post code>",
      has_nested_results: false,
    },
    {
      address_text: "GoCardless, Nested Goswell Road",
      id: "123",
      description: "London, EC1V 7EN",
      has_nested_results: false,
    },
    {
      address_text: "N19 3GG",
      id: NESTED_ADDRESS,
      description: "Mulkern Road, London - 15 Addresses",
      has_nested_results: true,
    },
    {
      address_text: "17A, Halliwick Road",
      id: "GB|RM|B|15582541",
      description: "London, N10 1AA",
      has_nested_results: false,
    },
    {
      address_text: "1 Halliwick Road",
      id: "GB|RM|B|15582536",
      description: "London, N10 1AA",
      has_nested_results: false,
    },
    {
      address_text: "3 Halliwick Road",
      id: "GB|RM|B|15582548",
      description: "London, N10 1AA",
      has_nested_results: false,
    },
    {
      address_text: "5 Halliwick Road",
      id: "GB|RM|B|15582559",
      description: "London, N10 1AA",
      has_nested_results: false,
    },
  ],
};

export const nestedAddresses: AddressLookupCreateResponseBody = {
  address_lookups: [
    {
      address_text: "Flat 1, Birbeck House, 2 Mulkern Road",
      id: "GB|RM|A|15712253",
      description: "London, N19 3GG",
      has_nested_results: false,
    },
    {
      address_text: "Flat 10, Birbeck House, 2 Mulkern Road",
      id: "GB|RM|A|15712254",
      description: "London, N19 3GG",
      has_nested_results: false,
    },
    {
      address_text: "Flat 11, Birbeck House, 2 Mulkern Road",
      id: "GB|RM|A|15712255",
      description: "London, N19 3GG",
      has_nested_results: false,
    },
    {
      address_text: "Flat 12, Birbeck House, 2 Mulkern Road",
      id: "GB|RM|A|15712256",
      description: "London, N19 3GG",
      has_nested_results: false,
    },
    {
      address_text: "Flat 13, Birbeck House, 2 Mulkern Road",
      id: "GB|RM|A|15712257",
      description: "London, N19 3GG",
      has_nested_results: false,
    },
    {
      address_text: "Flat 14, Birbeck House, 2 Mulkern Road",
      id: "GB|RM|A|15712258",
      description: "London, N19 3GG",
      has_nested_results: false,
    },
    {
      address_text: "Flat 15, Birbeck House, 2 Mulkern Road",
      id: "GB|RM|A|15712259",
      description: "London, N19 3GG",
      has_nested_results: false,
    },
    {
      address_text: "Flat 2, Birbeck House, 2 Mulkern Road",
      id: "GB|RM|A|15712260",
      description: "London, N19 3GG",
      has_nested_results: false,
    },
    {
      address_text: "Flat 3, Birbeck House, 2 Mulkern Road",
      id: "GB|RM|A|15712261",
      description: "London, N19 3GG",
      has_nested_results: false,
    },
    {
      address_text: "Flat 4, Birbeck House, 2 Mulkern Road",
      id: "GB|RM|A|15712262",
      description: "London, N19 3GG",
      has_nested_results: false,
    },
    {
      address_text: "Flat 5, Birbeck House, 2 Mulkern Road",
      id: "GB|RM|A|15712263",
      description: "London, N19 3GG",
      has_nested_results: false,
    },
    {
      address_text: "Flat 6, Birbeck House, 2 Mulkern Road",
      id: "GB|RM|A|15712264",
      description: "London, N19 3GG",
      has_nested_results: false,
    },
    {
      address_text: "Flat 7, Birbeck House, 2 Mulkern Road",
      id: "GB|RM|A|15712265",
      description: "London, N19 3GG",
      has_nested_results: false,
    },
    {
      address_text: "Flat 8, Birbeck House, 2 Mulkern Road",
      id: "GB|RM|A|15712266",
      description: "London, N19 3GG",
      has_nested_results: false,
    },
    {
      address_text: "Flat 9, Birbeck House, 2 Mulkern Road",
      id: "GB|RM|A|15712267",
      description: "London, N19 3GG",
      has_nested_results: false,
    },
  ],
};

export const addressDetail: AddressDetailSelfResponseBody = {
  address_details: {
    address_line1: "Flat 1",
    address_line2: "Example Court",
    address_line3: "65 Goswell Road",
    city: "London",
    postal_code: "EC1V 7EN",
  },
};

export const addressDetailTemplate: {
  address_details: AddressDetailResource;
} = {
  address_details: {
    address_line1: "<flat number>",
    address_line2: "<maybe building name>",
    address_line3: "<street number> <street name>",
    city: "London",
    postal_code: "N10 1AA",
  },
};

interface IMockBillingRequestFallenBack {
  payment_request?: Partial<BillingRequestsPaymentRequest>;
  mandate_request?: Partial<BillingRequestsMandateRequest>;
  collect_customer_details_completed?: ActionStatus;
  confirm_payer_details_completed?: ActionStatus;
}
export const mockBillingRequestFallenBack = ({
  payment_request,
  mandate_request,
  collect_customer_details_completed,
  confirm_payer_details_completed,
}: IMockBillingRequestFallenBack): Partial<BillingRequestResource> => {
  return billingRequestSelf({
    resources: {
      customer: customerSelf(),
      customer_bank_account: customerBankAccountSelf(),
      customer_billing_detail: {
        id: "CBD123",
        address_line1: null,
        address_line2: null,
        address_line3: null,
        city: null,
        region: null,
        postal_code: null,
        country_code: "GB",
        swedish_identity_number: null,
        danish_identity_number: null,
      },
    },
    fallback_enabled: true,
    fallback_occurred: true,
    payment_request: payment_request || {
      description: "First Payment",
      currency: "GBP",
      amount: 500,
      app_fee: null,
      scheme: "faster_payments",
      links: {},
    },
    mandate_request: mandate_request || {
      scheme: AvailableDebitSchemeEnum.Bacs,
      currency: Currency.Gbp,
      verify: MandateRequestVerify.Minimum,
    },
    actions: [
      {
        type: ActionType.ChooseCurrency,
        required: true,
        completes_actions: [],
        requires_actions: [],
        status: ActionStatus.Completed,
      },
      {
        type: ActionType.CollectCustomerDetails,
        required: true,
        completes_actions: [],
        requires_actions: [ActionType.ChooseCurrency],
        status: collect_customer_details_completed || ActionStatus.Pending,
      },
      {
        type: ActionType.SelectInstitution,
        required: false,
        completes_actions: [],
        requires_actions: [],
        status: ActionStatus.Pending,
      },
      {
        type: ActionType.CollectBankAccount,
        required: true,
        completes_actions: [ActionType.ChooseCurrency],
        requires_actions: [],
        status: ActionStatus.Completed,
      },
      {
        type: ActionType.ConfirmPayerDetails,
        required: true,
        completes_actions: [],
        requires_actions: [
          ActionType.CollectCustomerDetails,
          ActionType.CollectBankAccount,
        ],
        status: confirm_payer_details_completed || ActionStatus.Pending,
      },
    ],
  });
};

export const mockBillingRequestFulfilled = (
  data?: Partial<BillingRequestResource>
): Partial<BillingRequestResource> => {
  return billingRequestSelf(
    {
      resources: {
        customer: customerSelf(),
        customer_bank_account: customerBankAccountSelf(),
      },
      actions: [
        {
          type: ActionType.ChooseCurrency,
          required: true,
          completes_actions: [],
          requires_actions: [],
          status: ActionStatus.Completed,
        },
        {
          type: ActionType.CollectCustomerDetails,
          required: true,
          completes_actions: [],
          requires_actions: [ActionType.ChooseCurrency],
          status: ActionStatus.Completed,
        },
        {
          type: ActionType.CollectBankAccount,
          required: true,
          completes_actions: [ActionType.ChooseCurrency],
          requires_actions: [],
          status: ActionStatus.Completed,
        },
        {
          type: ActionType.ConfirmPayerDetails,
          required: true,
          completes_actions: [],
          requires_actions: [
            ActionType.CollectCustomerDetails,
            ActionType.CollectBankAccount,
          ],
          status: ActionStatus.Completed,
        },
      ],
      ...data,
      status: BillingRequestsStatus.Fulfilled,
    },
    BillingRequestsStatus.Fulfilled
  );
};

export const mandateRequestConstraintsSelf = (
  data?: Partial<MandateRequestConstraints>
): MandateRequestConstraints => {
  return {
    end_date: "2022-06-30",
    max_amount_per_payment: 100,
    periodic_limits: [
      {
        max_payments: 1,
        max_total_amount: 100,
        period: PeriodEnum.Month,
      },
    ],
    start_date: "2022-06-22",
    ...data,
  };
};

export const mandateMigrationSelf = (
  data?: Partial<MandateMigrationResource>
) => {
  return {
    id: "MM123",
    created_at: new Date(),
    status: MandateMigrationsStatus.Scheduled,
    ...data,
  };
};

export const generateInstitutionListResponseBody =
  (): InstitutionListResponseBody => {
    return {
      institutions: [
        {
          id: "STARLING_SRLGGB3L",
          name: "Starling Bank",
          logo_url:
            "https://images.yapily.com/image/17c5ba24-fff2-4ffd-b84d-b7697cb40232?size=0",
          icon_url:
            "https://images.yapily.com/image/398dcc4d-b350-4b8d-8d3d-cbd105eb9c63?size=0",
          autocompletes_collect_bank_account: true,
        },
        {
          id: "MONZO_MONZGB2L",
          name: "Monzo",
          logo_url:
            "https://images.yapily.com/image/332bb781-3cc2-4f3e-ae79-1aba09fac991?size=0",
          icon_url:
            "https://images.yapily.com/image/f70dc041-c7a5-47d3-9c6b-846778eac01a?size=0",
          autocompletes_collect_bank_account: true,
        },
        {
          id: "REVOLUT_REVOGB21",
          name: "Revolut",
          logo_url:
            "https://images.yapily.com/image/fe9124de-3dd2-4b6f-a519-ad5f9e2b594e?size=0",
          icon_url:
            "https://images.yapily.com/image/c3fbd5c1-37f6-41ab-a289-b8cd642f6367?size=0",
          autocompletes_collect_bank_account: true,
        },
        {
          id: "RBS_GB_RBSSGBKC",
          name: "Royal Bank of Scotland",
          logo_url:
            "https://images.yapily.com/image/7a689514-dda3-4550-917b-c0243a9eabd3?size=0",
          icon_url:
            "https://images.yapily.com/image/694b1b08-bb78-40d8-bf58-aae12a40de99?size=0",
          autocompletes_collect_bank_account: true,
        },
        {
          id: "rbsbusiness",
          name: "Royal Bank of Scotland Bankline",
          logo_url:
            "https://images.yapily.com/image/0aaec899-70ac-47e9-ab53-f53587c88c3c?size=0",
          icon_url:
            "https://images.yapily.com/image/23735cc4-18f6-4dbc-b67a-001ca9040c8c?size=0",
          autocompletes_collect_bank_account: true,
        },
      ],
    };
  };

export const generateBillingRequestSelfResponseBody = (
  options?: Partial<BillingRequestResource>
): BillingRequestSelfResponseBody => ({
  billing_requests: billingRequestSelf(options),
});

export const MOCK_CUSTOMER_ID = "CU123";

export const generateCustomerBankAccountsResponseBody = (
  options?: Partial<CustomerBankAccountResource>
): CustomerBankAccountSelfResponseBody => {
  return {
    customer_bank_accounts: {
      id: "BA0000QE207S7X",
      created_at: new Date("2021-04-21T14:01:14.006Z"),
      account_number_ending: "11",
      account_holder_name: "BS",
      account_type: null,
      bank_name: "BARCLAYS BANK PLC",
      currency: "GBP",
      country_code: "GB",
      metadata: {},
      enabled: true,
      links: {
        customer: MOCK_CUSTOMER_ID,
      },
      ...(options || {}),
    },
  };
};
